import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import {BlogRoll} from "./BlogRoll";

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollIndexQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 3
        ) {
          edges {
            node {
              excerpt(pruneLength: 120)
              id
              fields {
                slug
              }
              timeToRead
              frontmatter {
                author
                tags
                title
                templateKey
                date(formatString: "MMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 200, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)