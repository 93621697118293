import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import {MainBox, MainText, SubText} from "../components/MainComponents";
import MailChimpForm from "../components/MailchimpForm";
import Helmet from "react-helmet";
import BlogRollOnIndex from "../components/BlogRollOnIndex";

class IndexPage extends React.Component {
    constructor (props) {
        super(props)
        this.state = {mounted: false}
    }

    componentDidMount()
    {
        this.setState({mounted: true})
    }


    render() {
        const data = this.props.data;
        const {frontmatter} = data.markdownRemark

        return (
            <Layout>
                <div>
                    <div
                        className="full-width-image margin-top-0"
                        style={{
                            backgroundImage: `url("/img/layout/background-matrix.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )`,
                            backgroundPosition: `center`,
                            height: "450px",
                        }}
                    >
                        <MainBox>
                            <MainText>Získej znalosti IT Bezpečnosti</MainText>
                            <SubText>Pomáháme jednotlivcům a organizacím získat<br/> povědomí o bezpečnosti v
                                IT.</SubText>
                        </MainBox>
                    </div>
                    <section className="section--gradient">
                        <div className="container">
                            <div className="section">
                                <div className="columns">
                                    <div className="column is-10 is-offset-1">
                                        <div className="content">
                                            <div className="content">
                                                <h4 className="subtitle">{frontmatter.mainpitch.description}</h4>
                                            </div>
                                            <div className="columns">
                                                <div className="column is-12">
                                                    <h3 className="has-text-weight-semibold is-size-2">
                                                        {frontmatter.heading}
                                                    </h3>
                                                    <p>{frontmatter.description}</p>
                                                </div>
                                            </div>
                                            <Features gridItems={frontmatter.intro.blurbs}/>
                                            <div className="columns">
                                                <div className="column is-12 has-text-centered">
                                                    <Link className="btn" to="/nase-aktivity/">
                                                        Naše Aktivity
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="column is-12">
                                                <h3 className="has-text-weight-semibold is-size-2">
                                                    Poslední příspěvky
                                                </h3>
                                                <BlogRollOnIndex/>
                                                <div className="column is-12 has-text-centered">
                                                    <Link className="btn" to="/blog/">
                                                        Více článků
                                                    </Link>
                                                </div>
                                                <div className="column is-12 has-text-centered">
                                                    <MailChimpForm/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Helmet titleTemplate="Znalosti kyberbezpečnosti od expertů | TunaSec">
                    {/* General tags */}
                    <title>Znalosti kyberbezpečnosti od expertů | TunaSec</title>
                    <meta name="robots" content="index, follow" />
                    <meta name="keywords" content="bezpečnost, it security, it bezpečnost" />
                    <meta property="og:title" content="Znalosti kyberbezpečnosti od expertů | TunaSec"/>
                    <meta name="description" content="Více než 4 roky děláme osvětu v oblasti kyberbezpečnosti, píšeme články, testy webů i serverů a organizujeme události a soutěže." />
                    <meta name="og:description" content="Více než 4 roky děláme osvětu v oblasti kyberbezpečnosti, píšeme články, testy webů i serverů a organizujeme události a soutěže." />
                    <meta name="og:image" content="https://tunasec.com/img/og-images/tunasec.png" />
                </Helmet>
            </Layout>
        )
    }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
